<template>
  <div :class="firstStep">
    <CustomModalWithSelectedValue
      title="sale_area.currencyConverter"
      label="sale_area.chooseOne"
      type="currency"
      :list="currencies"
    />
  </div>
</template>

<script>
import CustomModalWithSelectedValue from '@/components/CustomModalWithSelectedValue/CustomModalWithSelectedValue.vue';

export default {
  name: 'CurrencyConverter',
  props: {
    currencies: {
      type: [Array, null],
    },
  },
  components: {
    CustomModalWithSelectedValue,
  },
  computed: {
    firstStep() {
      return {
        firstStep: this.$store.state.step === 1,
      };
    },
  },
};
</script>
<style scoped>
.firstStep {
  position: absolute;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  bottom: 25%;
}
</style>
