import { createStore } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import 'moment/locale/fr-ch';
import { Request } from '@/utils/request';
import { requestFactory } from '@/utils/requestFactory';
import { redirectToLogout } from '@/utils/commons';
import { formatPrice } from '@/utils/formatPrice';
import { useToast } from 'vue-toastification';
import { i18n } from '@/translation/translation';

const toast = useToast();

const handleCustomBid = (state) => {
  if (state.selectedBid && state.selectedBid < state.context.askingPrice) {
    state.selectedBid = null;
    toast.error(`${i18n.global.t('sale_area.bid_error')}`);
  }
};

const lotImgDecorator = (lotArray) => {
  return _.map(lotArray, (lot) => {
    return {
      ...lot,
      url_thumb: _.get(lot, 'lotImages[0].urlThumb'),
    };
  });
};

const updateLotsUnsold = function (state) {
  const catalogueId = _.get(state, 'catalogId');
  const requestUrl = `/lots/catalogue/${catalogueId}/unsale`;
  try {
    new Request(requestUrl, requestFactory.get())
      .setBody()
      .call()
      .then((result) => {
        state.lotsUnsold = _.map(result, (lot) => {return {id:lot.id};});
      });
  } catch (err) {
    console.log('ERROR ', err);
  }
};

const updateLotInCurrentState = function (state, catalogId) {
  const contextCatalogId = _.get(state, 'catalogId');
  if (!isNaN(catalogId) && contextCatalogId !== catalogId) {
    if (!state.catalogLoaded) {
      state.catalogLoaded = true;
    }
    const requestURL = `/lots/catalogue/${catalogId}`;
    try {
      const reqLots = new Request(requestURL);
      reqLots.call(requestFactory.get()).then((result) => {
        state.lots = lotImgDecorator(result);
        updateLotsData(state);
      });
    } catch (err) {
      // toast(<FormattedMessage {...messages.err.loadLot} />);
      console.log('ERROR ', err);
    }
  }
};

const updateLotsData = function (state) {
  if (!state.currentDisplayedLotId || _.isEmpty(state.lots)) return;
  const currentLotId = state.currentDisplayedLotId;
  const lot = _.find(state.lots, ['id', currentLotId]);
  state.currentLot = lot;
  const currentLotIndex = _.indexOf(state.lots, lot);
  if (currentLotIndex === 0) {
    state.previousLotId = null;
  } else {
    state.previousLotId = state.lots[currentLotIndex - 1].id;
  }
  if (currentLotIndex + 1 === state.lots.length) {
    state.nextLotId = null;
  } else {
    state.nextLotId = state.lots[currentLotIndex + 1].id;
  }
};

const updateLotsWon = function (state) {
  const catalogueId = _.get(state, 'catalogId');
  const requestUrl = `/users/catalogue/${catalogueId}/winning-sales`;
  try {
    new Request(requestUrl, requestFactory.get())
      .setBody()
      .call()
      .then((result) => {
        let lotsWon = [];
        _.forEach(result, function (value) {
          lotsWon.push({
            id: value.lotId,
            position: _.get(value, 'lot.position'),
            appellation: _.get(value, 'lot.appellation'),
            price: _.get(value, 'winningBid.amount'),
            millesime: _.get(value, 'lot.millesime'),
            paddle: _.get(value, 'winningBid.clientPaddle.paddleNumber'),
          });
        });
        lotsWon.sort(function compare(a, b) {
          if (a.position < b.position) return -1;
          if (a.position > b.position) return 1;
          return 0;
        });
        state.lotsWon = lotsWon;
      });
  } catch (err) {
    console.log('ERROR ', err);
  }
};

export default createStore({
  state: {
    cid: 0,
    step: 0,
    // price: 0,
    msgLotHasBisStart: 'sale_area.bids_start',
    msgLotHasBisEnd: 'sale_area.bids_end',
    msgStatusLot: false,
    isAdmin: false,
    isVisitor: false,
    isAllowedBuying: false,
    catalogLoaded: false,
    catalogId: Number,
    lotId: 0,
    catalogNames: {},
    endOfSaleTexts: {},
    catalogEndSentence: {},
    nbrLots: Number,
    lots: [],
    currentDisplayedLotId: null,
    previousLotId: null,
    nextLotId: null,
    absenteeBids: 0,
    lotsWon: [],
    lotsUnsold: [],
    currentLot: null,
    paddle: Number,
    selectedBid: null,
    genericTiming: 0,
    timingLot: new Date(0, 0, 0, 0, 0, 5),
    timingPrep: new Date(0, 0, 0, 0, 0, 5),
    timingPrice: new Date(0, 0, 0, 0, 0, 20),
    stepTotalTimes: {},
    currencies: [],
    catalogCurrency: null,
    selectedCurrency: {},
    currentCurrency: {},
    bagheraTime: moment(),
    pendingBidTurnData: null,
    context: {
      bidTurnId: 0,
      catalogueId: 0,
      lotId: 0,
      increments: null,
      lotSaleStatus: null,
      lotPosition: null,
      lotParcelle: null,
      askingPrice: null,
      lastAcceptedBidId: null,
      lastAcceptedBidAmount: null,
      lastAcceptedBidPaddleNumber: null,
      lastAcceptedBidCreatedAt: null,
      lastAcceptedBidType: null,
      lastAcceptedBidAbsenteeBidId: null,
      waitingLiveBidId: null,
      waitingLiveBidAmount: null,
      waitingLiveBidPaddleNumber: null,
      waitingLiveBidCreatedAt: null,
    },
  },
  mutations: {
    changeIsVisitor(state, data) {
      state.isVisitor = data;
    },
    changeIsAdmin(state, data) {
      state.isAdmin = data;
    },
    changeCid(state, cid) {
      state.cid = cid;
    },
    set_step(state, data) {
      state.step = data;
    },
    setCurrentDisplayedLotId(state, data) {
      state.currentDisplayedLotId = data;
      updateLotsData(state);
    },
    resetTiming() {
      this.state.genericTiming = 0;
      this.state.timingLot = new Date(0, 0, 0, 0, 0, 5);
      this.state.timingPrep = new Date(0, 0, 0, 0, 0, 5);
      this.state.timingPrice = new Date(0, 0, 0, 0, 0, 20);
    },
    SOCKET_updateContext(state, data) {
      updateLotInCurrentState(state, _.get(data, 'catalogueId'));
      if (
        state.pendingBidTurnData &&
        state.pendingBidTurnData.bidTurnId !== data.bidTurnId &&
        state.pendingBidTurnData.paddleNumber ===
          data.lastAcceptedBidPaddleNumber &&
        state.pendingBidTurnData.amount === data.lastAcceptedBidAmount
      ) {
        toast.success(
          `${i18n.global.t('toast.bidWon')}\n${formatPrice(
            data.lastAcceptedBidAmount,
          )} ${state.catalogCurrency}`,
        );
        state.pendingBidTurnData = null;
      } else if (
        state.pendingBidTurnData &&
        state.pendingBidTurnData.bidTurnId !== data.bidTurnId &&
        data.lastAcceptedBidType === 'ABSENTEE'
      ) {
        toast.success(
          `${i18n.global.t('toast.bidRejectedYour')} ${formatPrice(
            data.lastAcceptedBidAmount,
          )} ${state.catalogCurrency}\n${i18n.global.t('toast.bidRejected')}`,
        );
        state.pendingBidTurnData = null;
      }
      const newLotId = _.get(data, 'lotId');
      if (!isNaN(newLotId) && newLotId !== null) {
        if (!_.isEqual(state.context.lotId, newLotId)) {
          updateLotsWon(state);
          updateLotsUnsold(state);
        }
        if (_.isNull(state.currentDisplayedLotId)) {
          state.currentDisplayedLotId = newLotId;
          updateLotsData(state)
        }
        state.lotId = newLotId;
      }
      state.context = data;
      const { lastAcceptedBidType, lastAcceptedBidPaddleNumber } = data;
      if (
        state.step !== 4 &&
        lastAcceptedBidType === 'LIVE' &&
        lastAcceptedBidPaddleNumber !== state.paddle
      ) {
        toast(
          `${i18n.global.t('toast.newBidder')}\n${formatPrice(
            data.lastAcceptedBidAmount,
          )} ${state.catalogCurrency}`,
        );
      }
      if (state.step !== 4 && lastAcceptedBidType === 'ABSENTEE') {
        toast(
          `${i18n.global.t('toast.absentee')}\n${formatPrice(
            data.lastAcceptedBidAmount,
          )} ${state.catalogCurrency}`,
        );

      }
    },
    SOCKET_allow_buying(state, data) {
      state.isAllowedBuying = !!data.allow;
    },
    SOCKET_set_prep_timing(state, data) {
      state.step = 1;
      state.timingPrep = new Date(0, 0, 0, 0, 0, data.timing);
      state.genericTiming = data.timing;
      if (_.isNull(state.currentDisplayedLotId)) {
        state.currentDisplayedLotId = state.lotId;
        updateLotsData(state);
      }
    },
    SOCKET_set_paddle(state, data) {
      if (data === -1) {
        redirectToLogout();
      }
      state.paddle = data;
    },
    SOCKET_set_pres_timing(state, data) {
      state.step = 2;
      if (!state.currentLot) {
        state.currentLot = state.lots.find((lot) => lot.id === state.lotId);
      }
      const index = state.lots.findIndex((lot) => lot.id === state.lotId);
      if (!state.nextLotId && state.lots.length) {
        state.nextLotId = state.lots[index + 1].id;
      }
      if (!state.previousLotId && index > 0) {
        state.previousLotId = state.lots[index - 1].id;
      }
      state.context.lotId = state.lotId;
      state.timingLot = new Date(0, 0, 0, 0, 0, data.timing);
      state.genericTiming = data.timing;
    },
    SOCKET_set_turn_timing(state, data) {
      state.step = 3;
      state.isAllowedBuying = true;
      state.timingPrice = new Date(0, 0, 0, 0, 0, data.timing);
      state.genericTiming = data.timing;
      if (data.timing === 0) {
        state.selectedBid = null;
        state.step = 4;
        updateLotsWon(state);
        updateLotsUnsold(state);
      }
    },
    SOCKET_set_catalog(state, data) {
      const newCatalogId = _.get(data, 'catalog.id');
      const catalogCurrency = _.get(data, 'catalog.devise');
      updateLotInCurrentState(state, newCatalogId);
      updateLotsWon(state);
      updateLotsUnsold(state);
      state.catalogId = newCatalogId;
      state.catalogCurrency = catalogCurrency;
      state.selectedCurrency = catalogCurrency;
      state.currentCurrency = _.find(this.state.currencies, {
        currency: state.selectedCurrency,
      });
      state.catalogNames = {
        nom_cn: `${_.get(data, 'catalog.nomCn')}`,
        nom_en: `${_.get(data, 'catalog.nomEn')}`,
        nom_fr: `${_.get(data, 'catalog.nomFr')}`,
      };
      state.startSaleMsg = {
        start_text_fr: `${_.get(data, 'catalog.startTextFr')}`,
        start_text_en: `${_.get(data, 'catalog.startTextEn')}`,
        start_text_cn: `${_.get(data, 'catalog.startTextCn')}`,
      };
      state.endOfSaleTexts = {
        end_text_fr: `${_.get(data, 'catalog.endTextFr')}`,
        end_text_en: `${_.get(data, 'catalog.endTextEn')}`,
        end_text_cn: `${_.get(data, 'catalog.endTextCn')}`,
      };
      state.stepTotalTimes = {
        prepTime: data.prepTime,
        presTime: data.presTime,
        bidTime: data.bidTime,
        nextTime: data.nextTime,
        warnTime: data.warnTime,
      };
    },
    SOCKET_reset(state) {
      state.lotsWon = [];
    },
    SOCKET_set_lot(state, data) {
      const currentLot = state.lots.find((lot) => lot.id === data.lotId);
      if (currentLot) {
        toast.success(`${i18n.global.t('toast.lot')} #${currentLot.position}`);
        state.msgStatusLot = false;
      }
      updateLotsWon(state);
      updateLotsUnsold(state);
      if (
        _.isEqual(state.lotId, state.currentDisplayedLotId) ||
        _.isNull(state.currentDisplayedLotId)
      ) {
        state.currentDisplayedLotId = data.lotId;
        updateLotsData(state);
        state.currentLot = state.lots.find((lot) => lot.id === data.lotId);
      }
      state.lotId = data.lotId;
    },
    SOCKET_set_price() {
      // state.price = data.price;
    },
    SOCKET_lost_lot(state) {
      state.msgStatusLot = 'sale_area.unsold';
    },
    SOCKET_share_buy(state, data) {
      if (data.buy === 1) {
        state.lotsWon.push(data.lot);
        state.msgStatusLot = 'sale_area.bought';
      } else {
        state.msgStatusLot = 'sale_area.sold';
      }
    },
    SOCKET_end_buy(state) {
      state.step = 5;
    },
    SET_selected_bid(state, payload) {
      state.selectedBid = payload;
      handleCustomBid(state);
    },
    set_currencies(state, payload) {
      state.currencies = payload;
    },
    setSelectedCurrency(state, payload) {
      state.selectedCurrency = payload;
      state.currentCurrency = _.find(this.state.currencies, {
        currency: payload,
      });
    },
    setLotsWon(state, payload) {
      state.lotsWon = payload;
    },
  },
  getters: {
    isCurrentDisplayedLotSelling: (state) => {
      return state.lotId === state.currentDisplayedLotId;
    },
    isPreviousArrowHidden: (state) => {
      return !!_.isNull(state.previousLotId);
    },
    isNextArrowHidden: (state) => {
      return !!_.isNull(state.nextLotId);
    },
    isCurrentLotUnsold: (state) =>{
      return _.some(state.lotsUnsold, ['id', state.currentDisplayedLotId]);
    },
    myLotsWon: (state) => {
      if (!state.paddle || _.isEmpty(state.lotsWon)) return [];
      return _.filter(state.lotsWon, { paddle: state.paddle });
    },
    // isWarnTimeUp: (state) => {
    //   return !!_.isUndefined(state.stepTotalTimes.warnTime);
    // },
  },
  actions: {
    buy_current({ state, commit }) {
      const bidTurnId = _.get(state, 'context.bidTurnId');
      const selectedBid = _.get(state, 'selectedBid');
      const paddleNumber = _.get(state, 'paddle');
      if (!isNaN(bidTurnId)) {
        const askingPrice = _.get(state, 'context.askingPrice');
        const amount = selectedBid ? selectedBid : askingPrice;
        const requestURL = `/bid-turns/${bidTurnId}/new-live-bid`;
        state.pendingBidTurnData = {
          bidTurnId: bidTurnId,
          amount: amount,
          paddleNumber: paddleNumber,
        };
        commit('SET_selected_bid', null);
        try {
          new Request(requestURL, requestFactory.post())
            .setBody({ paddleNumber, amount })
            .call();
        } catch (err) {
          toast.error(`${i18n.global.t('toast.bidRejected')}`);
          console.log('ERROR ', err);
        }
      }
    },
    goToPreviousLot({ state, commit }) {
      commit('setCurrentDisplayedLotId', state.previousLotId);
    },
    goToNextLot({ state, commit }) {
      commit('setCurrentDisplayedLotId', state.nextLotId);
    },

    getCurrencies({ commit }) {
      const requestUrl = '/currencies';
      try {
        const currencies = new Request(requestUrl);
        currencies.call(requestFactory.get()).then((result) => {
          commit('set_currencies', result);
        });
      } catch (err) {
        console.log('ERROR ', err);
      }
    },

    /*SOCKET_set_step(state, data) {
      console.log('SOCKET_set_step', data);
      state.commit('set_step', data.step);
      state.commit('resetTiming');
    },
    SOCKET_end_buy(state) {
      state.commit('set_step', 4);
      state.commit('resetTiming');
    },*/
  },
});
