<template>
  <footer class="footer">
    <FairWarningVue :timer="timingPrice" />
    <CustomTimerBanner />
  </footer>
</template>
<script>
import CustomTimerBanner from '@/components/CustomTimerBanner/CustomTimerBanner';
import FairWarningVue from '@/components/FairWarning/FairWarning.vue';
export default {
  name: 'Footer',
  components: {
    CustomTimerBanner,
    FairWarningVue,
  },
  computed: {
    timingPrice() {
      return Math.max(this.$store.state.timingPrice.getSeconds() - 1, 0);
    },
    isCurrentDisplayedLotSelling() {
      return this.$store.getters.isCurrentDisplayedLotSelling;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('../../assets/scss/footer.css');
</style>
