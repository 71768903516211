<template>
  <h1 class="title">{{ $t('lobby.wineOClock') }}</h1>
  <p class="catalog">
    {{ catalogName }}
    <span class="separator">|</span>
    {{ nbrLots }} {{ $t('lobby.lots') }}
  </p>
  <p v-if="isFirstStep" class="startText">
    {{ startSaleMsg[`start_text_${$i18n.locale}`] }}
  </p>
</template>
<script>
export default {
  name: 'CatalogTitle',
  props: {
    catalogName: String,
    nbrLots: Number,
  },
  computed: {
    startSaleMsg() {
      return this.$store.state.startSaleMsg;
    },
    isFirstStep() {
      return this.$store.state.step === 1;
    },
  },
};
</script>
<style scoped>
.title {
  margin-bottom: 0;
  margin-top: 5px;
  font-family: 'Lato Light';
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
}
.startText {
  color: rgba(0, 0, 0, 1);
  font-family: 'Lato Light';
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
}
.catalog {
  font-family: 'Lato Bold';
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-transform: uppercase;
  margin-top: 2px;
}
.separator {
  font-weight: normal;
  color: lightgray;
}
</style>
