<template>
  <div class="wrapper" :class="{ '--lot-won': isLotWon }">
    <div class="priceStyle">
      {{ selectedBid ? selectedBid : askingPrice }} {{ catalogCurrency }}
    </div>
    <div>
      <vue-slider
        class="slider"
        :disabled="isLotWon"
        :min="min"
        :max="max"
        :tooltip="tooltip"
        v-model="sliderValue"
        :dot-style="dotStyle"
        :rail-style="railStyle"
        :process-style="processStyle"
        :process-dragable="true"
        @drag-end="dragEnd"
        @dragging="dragging"
      >
        <template v-slot:dot="{ focus }">
          <div :class="['custom-dot', { focus }, { 'lot-won': isLotWon }]">
            <img
              v-if="isLotWon"
              :src="require(`@/assets/icons/xmark-thin.svg`)"
              class="filter-white icon-won"
              alt="slider"
            />
            <img
              v-else
              :src="require(`@/assets/icons/arrow-right-thin.svg`)"
              class="filter-white"
              alt="slider"
            />
          </div>
        </template>
      </vue-slider>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import { formatPrice } from '@/utils/formatPrice';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'SliderButton',
  emits: ['onBuy'],
  props: {
    isLotWon: Boolean,
  },
  components: {
    VueSlider,
  },
  data() {
    return {
      min: 0,
      max: 50,
      value: 0,
      tooltip: 'none',
      sliderValue: 0,
      dotStyle: {
        width: '40px',
        height: '40px',
        boxShadow: 'none',
        backgroundColor: 'black',
      },
      processStyle: {
        display: 'none',
      },
      railStyle: {
        backgroundColor: 'white',
      },
    };
  },
  methods: {
    dragEnd: function () {
      if (this.sliderValue === 50) {
        this.buyThis();
      }
      this.resetSlide();
    },
    dragging: function (value) {
      this.sliderValue = value;
    },
    resetSlide() {
      this.sliderValue = 0;
    },
    buyThis() {
      if (this.isAllowedBuying) {
        this.$store.dispatch('buy_current');
      }
    },
  },
  computed: {
    askingPrice() {
      return formatPrice(this.$store.state.context.askingPrice);
    },
    selectedBid() {
      return formatPrice(this.$store.state.selectedBid);
    },
    isAllowedBuying() {
      return this.$store.state.isAllowedBuying;
    },
    catalogCurrency() {
      return this.$store.state.catalogCurrency;
    },
  },
};
</script>

<style scoped>
.custom-dot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: black;
  border-radius: 40px;
  cursor: pointer;
}
.filter-white {
  filter: brightness(0) invert(1);
  width: 22px;
  pointer-events: none;
}
.lot-won {
  background-color: #cdcdcd;
  cursor: default;
}
.icon-won {
  width: 15px;
}
.wrapper {
  position: relative;
  padding-left: 10px;
  padding-right: 36px;
  border: 1px solid lightgray;
  width: 220px;
  height: 46px;
  border-radius: 40px;
  margin-top: 6px;
  z-index: 0;
  margin-right: auto;
  margin-left: auto;
}
.priceStyle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: rgba(0, 0, 0, 1);
  font-family: 'Lato Bold';
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
}
</style>
