<template>
  <div class="banner-position">
    <span class="presentation">{{ presentation }}</span>
    <span class="timer-data">{{ timer }}</span>
    <div class="flex-title">
      <span class="subtitle-seconds">{{ subtitleSeconds }}</span>
      <span class="title">{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressStepInfos',
  props: {
    timer: Number,
    title: String,
    subtitleSeconds: String,
    presentation: String,
  },
};
</script>
<style scoped>
.banner-position {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 7px;
  color: #fff;
}
.timer-data {
  color: rgba(255, 255, 255, 1);
  font-family: 'Lato Bold';
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-transform: uppercase;
}
.flex-title {
  display: flex;
  flex-direction: column;
}
.subtitle-seconds {
  margin-bottom: -9px;
  color: rgba(255, 255, 255, 1);
  font-family: 'Lato Bold';
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
}
.title {
  color: rgba(255, 255, 255, 1);
  font-family: 'Lato Bold Italic';
  font-size: 14px;
  font-weight: 700;
  font-style: italic;
  letter-spacing: 0px;
}
.presentation {
  font-size: 18px;
  text-align: center;
  margin-top: 16px;
  text-transform: uppercase;
  font-weight: 700;
  opacity: 1;
}
</style>
