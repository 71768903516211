import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import socketInstance from './plugins/socketInstance';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import store from './store';
import { i18n } from './translation/translation';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import '../src/assets/scss/toast.scss';

const toastOptions = {
  icon: false,
  hideProgressBar: true,
};

library.add(fas);
createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(socketInstance)
  .use(Toast, toastOptions)
  .component('fa', FontAwesomeIcon)
  .mount('#app');
