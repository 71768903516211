import { createI18n } from 'vue-i18n/index';
import { getSessionItem } from '@/utils/sessions';

import { en } from './en';
import { fr } from './fr';
import { cn } from './cn';

const messages = {
  en: en,
  fr: fr,
  cn: cn,
};

export const i18n = createI18n({
  locale: getSessionItem('language') || 'en',
  fallbackLocale: getSessionItem('language') || 'en',
  messages,
  globalInjection: true,
});
