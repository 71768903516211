import axios from 'axios';
//import { toast } from 'react-toastify';
import { bagheraAuctionUrl } from './serverConf';
import { redirectToLogout } from '@/utils/commons';
//import { errToast } from '../utils/toastUtils';

let requestFactory = null;

// Erreurs à dupliquer dans livebidding-server (libs/passport.js)
const ERROR = {
  ROLE: 'NO_ROLE',
  PARTICIPATION: 'NO_PARTICIPATION',
  ADMIN: 'NOT_ADMIN',
  JWT: 'JWT_ERROR',
};

export class RequestFactory {
  http;
  constructor(baseUrl, jwtToken) {
    this.http = axios.create({
      baseURL: baseUrl,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    requestFactory = this;

    // Interceptor qui va vérifier le statut des réponses
    this.http.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        let lang = localStorage.getItem('language') ?? "fr";
        let catalogue = localStorage.getItem('catalogue');
        switch (error.response.data) {
          case ERROR.PARTICIPATION:
          case ERROR.ADMIN:
            if (catalogue) {
              window.location.href = `${bagheraAuctionUrl}/${lang}/catalogue/voir/${catalogue}?error=${error.response.data}`;
              break;
            } else {
              window.location.href = `${bagheraAuctionUrl}/${lang}/catalogue/index?error=${error.response.data}`;
              break;
            }

          case ERROR.ROLE:
          case ERROR.JWT:
            redirectToLogout();
            break;

          default:
            //errToast('Erreur');
            break;
        }
        return Promise.reject(error);
      },
    );
  }

  post() {
    return this.http.post;
  }

  get() {
    return this.http.get;
  }

  put() {
    return this.http.put;
  }

  patch() {
    return this.http.patch;
  }

  delete() {
    return this.http.delete;
  }
}

export { requestFactory };
