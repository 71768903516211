<template>
  <div v-if="type === 'bid'" class="btn-bid" @click="handleModal()">
    {{ $t(title) }}
  </div>

  <div
    v-if="type === 'currency'"
    class="converter-label"
    @click="handleModal()"
  >
    <CustomFlagImg :label="selectedCurrency" />

    {{ $t(title) }}
  </div>

  <div class="modal" :class="isOpen && 'show'" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t(title) }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="handleModal()"
          >
            <img
              :src="require(`@/assets/icons/xmark-thin.svg`)"
              class="filter-white"
              alt="btn"
            />
          </button>
        </div>
        <div class="modal-body">
          <ul class="list-group" v-if="type === 'bid'">
            <li
              v-for="item in incrementList"
              v-bind:key="item"
              class="list-group-item"
              :class="{ active: isActive === item }"
              @click="(isActive = item), setSelectedBid(item)"
            >
              {{ formatPrice(item) }} {{ catalogCurrency }}
            </li>
          </ul>

          <ul class="list-group" v-if="type === 'currency'">
            <li
              v-for="(item, key) in incrementList"
              :key="key"
              class="list-group-item"
              :class="{ active: isActive === item }"
              @click="(isActive = item), setSelectedCurrency(item)"
            >
              <CustomFlagImg :label="item.currency" />
              <span class="currency">
                {{ item.currency }}
                {{
                  item.currency === catalogCurrency
                    ? $t('sale_area.defaultCurrency')
                    : ''
                }}
              </span>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <span class="selected" @click="handleSubmit()">
            {{ $t(label) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomFlagImg from '@/components/CustomFlagImg/CustomFlagImg.vue';
import { formatPrice } from '@/utils/formatPrice';
import _ from 'lodash';

export default {
  name: 'CustomModalWithSelectedValue',
  components: {
    CustomFlagImg,
  },
  props: {
    title: String,
    label: String,
    list: Array,
    type: String,
  },
  data() {
    return {
      incrementList: [],
      isOpen: false,
      isActive: undefined,
      newSelectedBid: null,
      newSelectedCurrency: null,
    };
  },
  computed: {
    catalogCurrency() {
      return this.$store.state.catalogCurrency;
    },
    selectedCurrency() {
      return this.$store.state.selectedCurrency;
    },
    selectedBid() {
      return this.$store.state.selectedBid;
    },
    formatPrice() {
      return (value) => formatPrice(value);
    },
    step() {
      return this.$store.state.step;
    },
  },
  watch: {
    step (){
     if(this.isOpen){
       this.isOpen = false;
     }
    }
  },
  methods: {
    handleModal() {
      if (!this.isOpen) {
        this.incrementList = _.clone(this.list);
      }
      this.isOpen = !this.isOpen;
    },
    handleSubmit() {
      if (this.type === 'bid' && this.newSelectedBid) {
        this.$store.commit('SET_selected_bid', this.newSelectedBid);
        this.newSelectedBid = null;
      }
      if (this.type === 'currency' && this.newSelectedCurrency) {
        this.$store.commit('setSelectedCurrency', this.newSelectedCurrency);
        this.newSelectedCurrency = null;
      }
      this.isOpen = !this.isOpen;
    },
    setSelectedCurrency(value) {
      this.newSelectedCurrency = value.currency;
    },
    setSelectedBid(value) {
      this.newSelectedBid = value;
    },
  },
};
</script>
<style scoped>
.filter-white {
  filter: brightness(0) invert(1);
  width: 9px;
}
.btn-bid {
  width: fit-content;
  margin: auto;
  margin-top: 25px;
  padding: 5px 12px 5px 12px;
  color: rgba(0, 0, 0, 1);
  border: 1px solid rgba(179, 179, 179, 1);
  background-color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  text-transform: uppercase;
  cursor: pointer;
  font-family: 'Lato Regular';
}
.converter-label {
  position: relative;
  width: fit-content;
  margin: auto;
  margin-top: 30px;
  cursor: pointer;
  color: rgba(179, 179, 179, 1);
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  font-family: 'Lato Bold';
}
.modal {
  background-color: #000000b3;
}
.modal-dialog {
  text-align: center;
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  max-width: 345px;
}
.modal-content {
  border-radius: 0.5rem;
}
.modal-header {
  background-color: #000000;
  padding: 10px;
  border-top-left-radius: calc(0.5rem - 1px) !important;
  border-top-right-radius: calc(0.5rem - 1px) !important;
}
.modal-title {
  width: 100%;
  margin-left: 24px; /* The width value of the close icon in ordeer to be perfectly centered  */
  color: rgba(255, 255, 255, 1);
  font-family: 'Lato Regular';
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
}
.modal-body {
  height: 42vh;
  overflow: scroll;
  padding: 0 12px;
}
.modal-footer {
  justify-content: center;
  border-top: 1px solid #000000;
  z-index: 1;
}
.close {
  background-color: transparent;
  border: none;
}
.close span {
  color: rgba(255, 255, 255, 1);
  font-family: 'FontAwesome6Pro-Light';
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
}
.list-group-item {
  border-left: none;
  border-right: none;
  padding: 14px;
  cursor: pointer;
  color: rgba(0, 0, 0, 1);
  font-family: 'Lato Regular';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  text-transform: uppercase;
}
.list-group-item:hover {
  background-color: #d3d3d33e;
}
.active {
  background-color: #ffff00;
  border-top-width: 0;
  border-bottom-width: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.active:hover {
  background-color: #ffff00; /* CSS hack to replace the hover color by the yellow selected color */
}
.show {
  display: block;
}
.selected {
  cursor: pointer;
  font-weight: bold;
  border: 1px solid black;
  margin: 15px;
  padding: 13px;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: 0 3px 9px rgb(0 0 0 / 39%);
  color: rgba(0, 0, 0, 1);
  font-family: 'Lato Bold';
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: uppercase;
}
.currency {
  margin-top: 11.5px; /* Feet the height of content to avoid scroll in modal for currency */
  display: block;
}
</style>
