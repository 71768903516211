<template>
  <div class="progressBarContainer">
    <ProgressStepInfos
      v-show="
        (step === 1 || step === 2 || step === 3 || step === 4) && isCurrentDisplayedLotSelling
      "
      :timer="timer"
      :subtitle-seconds="subtitleSeconds"
      :title="title"
      :presentation="presentation"
      :key="timer"
    />
    <ProgressBar v-show="isCurrentDisplayedLotSelling" />
    <back-to-sale-button v-show="!isCurrentDisplayedLotSelling && step >= 2" />
  </div>
</template>
<script>
import ProgressStepInfos from '@/components/ProgressStepInfos/ProgressStepInfos.vue';
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue';
import BackToSaleButton from '../BackToSaleButton/BackToSaleButton';
export default {
  name: 'CustomTimerBanner',
  components: { ProgressStepInfos, ProgressBar, BackToSaleButton },
  data() {
    return {
      timer: null,
      subtitleSeconds: '',
      title: '',
      presentation: '',
      componentKey: 0,
    };
  },
  computed: {
    step() {
      return this.$store.state.step;
    },
    timerBid() {
      return Math.max(this.$store.state.timingPrice.getSeconds() - 1, 0);
    },
    timerPrep() {
      return Math.max(this.$store.state.timingPrep.getSeconds() - 1, 0);
    },
    isCurrentDisplayedLotSelling() {
      return this.$store.getters.isCurrentDisplayedLotSelling;
    },
  },
  methods: {
    generateTimerProgressBarContent() {
      if (this.step === 1) {
        this.title = this.$t('timer_progress_bar.timer_text_prep');
        this.subtitleSeconds = this.$t('timer_progress_bar.timer_sec');
        this.presentation = '';
        this.timer = this.timerPrep;
      } else if (this.step === 2) {
        this.title = null;
        this.presentation = this.$t('timer_progress_bar.timer_text_pres');
        this.subtitleSeconds = null;
        this.timer = null;
      } else if (this.step === 3) {
        this.title = this.$t('timer_progress_bar.timer_text_bid');
        this.subtitleSeconds = this.$t('timer_progress_bar.timer_sec');
        this.timer = this.timerBid;
        this.presentation = '';
      } else if (this.step === 4) {
        this.title = null;
        this.subtitleSeconds = null;
        this.timer = null;
        this.presentation = this.$t('timer_progress_bar.timer_text_next');
      }
    },
    backToSale() {
      this.$store.commit(
        'setCurrentDisplayedLotId',
        this.$store.state.context.lotId,
      );
    },
  },
  watch: {
    step() {
      this.generateTimerProgressBarContent();
    },
    timerBid() {
      this.generateTimerProgressBarContent();
    },
    timerPrep() {
      this.generateTimerProgressBarContent();
    },
  },
};
</script>

<style>
.progressBarContainer {
  z-index: 100;
  width: 100%;
  height: 60px;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: black;
  display: flex;
  flex-direction: column;
}
</style>
