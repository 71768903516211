import VueSocketIO from 'vue-3-socket.io';
import io from 'socket.io-client';
import store from '../store';
import { RequestFactory } from '../utils/requestFactory';
import { baseUrl, socketUrl } from '../utils/serverConf';

export default async function socketInstance(app) {
  const currentUrl = window.location.search;
  let jwt = new URLSearchParams(currentUrl).get('jwt');
  if (!jwt) {
    const token = localStorage.getItem('jwt');
    if (token) {
      jwt = localStorage.getItem('jwt');
    } else {
      store.commit('changeIsVisitor', true);
    }
  } else {
    localStorage.setItem('jwt', jwt);
  }
  new RequestFactory(baseUrl, jwt);
  store.commit('changeCid', jwt); // result.data.CID
  let socket = io(socketUrl, {
    query: { token: jwt },
    transports: ['websocket'],
  });
  console.log(' :socket:', socketUrl);

  app.config.globalProperties.$socket = socket;
  const instance = new VueSocketIO({
    debug: true,
    connection: socket,
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_',
    },
  });
  app.use(instance);
}
