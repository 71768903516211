<template>
  <img
    :src="imageSrc"
    alt="Baghera/wines"
    class="img"
    :class="[presentationStep, saleStep]"
  />
</template>

<script>
import _ from 'lodash';
import { imageUrl } from '@/utils/serverConf';

export default {
  name: 'LotPicture',
  computed: {
    presentationStep() {
      return {
        presentationStep:
          this.$store.state.step === 2 && this.isCurrentDisplayedLotSelling,
      };
    },
    saleStep() {
      return {
        saleStep:
          this.isCurrentDisplayedLotSelling &&
          (this.$store.state.step === 3 || this.$store.state.step === 4),
      };
    },
    isCurrentDisplayedLotSelling() {
      return this.$store.getters.isCurrentDisplayedLotSelling;
    },
    imageSrc() {
      const currentLotImage = _.get(this, '$store.state.currentLot.url_thumb');
      if (currentLotImage) {
        return `${imageUrl}${currentLotImage}`;
      } else {
        return require(`@/assets/demo.png`);
      }
    },
  },
};
</script>
<style scoped>
.presentationStep {
  width: 100% !important;
}
.saleStep {
  width: 140px !important;
}
</style>
