export const fr = {
  header: {
    about: 'À propos',
    blog: 'Blog',
    contact: 'Contact',
    account: 'Mon espace',
    help: 'Aide',
  },
  footer: {
    legal_info: 'Mentions légales',
    privacy: 'Confidentialité & cookies',
    time: 'Chez Baghera/wines, il est',
  },
  lobby: {
    wineOClock: "Wine O'clock",
    type: 'En ligne',
    sale: 'Vente',
    lots: 'lots',
    vac_start: "Une Wine O'Clock (WOC) va débuter dans ",
    vac_timer: '0 heures 0 minutes {timing} secondes',
  },
  price_area: {
    timer_sec: 'sec',
    timer_text_level: '... Avant palier suivant',
    timer_text_lot: '... De présentation du lot',
    timer_text_prep: '... De préparation',
    price_subtitle: '... actuellement',
    currency: 'CHF',
  },
  sale_area: {
    title: 'Catalogue',
    lot: 'LOT',
    buy_button: 'Acheter',
    bids_start: 'Attention, des ordres ont',
    bids_end: 'déjà été posés sur ce lot',
    bid_error: 'Votre enchère est invalide',
    unsold: 'invendu',
    bought: 'Achat enregistré',
    sold: 'LE LOT EST VENDU A',
    lotWon: 'LE LOT EST À VOUS A',
    chooseYourBid: 'CHOOSE YOUR BID',
    setYourBid: 'Set your bid',
    highestBidder: 'Vous êtes actuellement le plus offrant',
    slideToBuy: 'glisser pour acheter',
    estimate: 'Estimation',
    currentBid: 'Enchère actuelle',
    currencyConverter: 'Convertisseur de devises',
    defaultCurrency: '(défaut)',
    chooseOne: 'choisissez-en un',
    unsoldLot: "Le lot n'est pas vendu",
  },
  details_area: {
    title: 'le lot en détail...',
    catalog_title: 'le catalogue',
    catalog_text:
      'Si vous souhaitez consulter le catalogue en ligne alors que la vente au\n' +
      '        cadran est en cours, un nouvel onglet s’ouvrira afin de ne pas quitter\n' +
      '        la vente.',
    catalog_link: 'consulter le catalogue',
    wine_title: 'informations relatives au lot',
    wine_country: "Pays d'origine",
    wine_area: 'Région',
    wine_subdivision: 'Subdivision',
    wine_produceur: 'Producteur',
    wine_classification: 'Classement',
    wine_vintage: 'Millésime',
    wine_titling: 'Titrage',
    lot_title: 'informations relatives au lot',
    lot_type: 'Type',
    lot_format: 'Format',
    lot_quantity: 'Quantité',
    lot_nature: 'Nature',
    lot_capacity: 'Contenance',
    lot_condition: 'Etat',
    lot_comment: 'Commentaire',
    lot_colisage: 'Colisage',
  },
  lot_area: {
    lots: 'tous les lots',
    bids: 'mes lots remportés',
    bids_next: '(hors commission acheteur de 22% HT)',
  },
  sale_end: {
    first: "La Wine O'Clock (WOC) est terminée pour le catalogue",
    final: 'Merci de votre participation',
    greetings: 'À bientôt !',
    atLeastOneLotWon:
      'Vous avez gagné au moins un lot.\n' +
      'Veuillez vous rendre sur votre compte pour vous assurer de vos\n' +
      "informations de facturation et d'expédition.",
  },
  toast: {
    absentee: 'OFFRE DU COMMISSAIRE-PRISEUR',
    newBidder: 'NOUVELLE ENCHERE',
    lot: 'ACTUELLEMENT SUR LE LOT',
    bidWon: "L'OFFRE EST AVEC VOUS",
    bidRejectedYour: 'VOTRE OFFRE DE',
    bidRejected: "N'A PAS ÉTÉ RETENUE",
    bidPrioritized: "L'OFFRE ÉCRITE A ÉTÉ PRIORISÉE A",
  },
  navigation: {
    back_to_sale: 'Retour à la vente',
  },
  fairWarning: {
    desc: 'FAIR WARNING',
  },
  timer_progress_bar: {
    timer_sec: ' secondes restantes',
    timer_text_pres: 'présentation',
    timer_text_prep: 'avant le premier lot',
    timer_text_next: 'TEMPS ÉCOULÉ',
    timer_text_bid: 'pour acheter ce lot',
  },
};
