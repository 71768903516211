<template>
  <div v-on:click="backToSale()" class="back-to-sale-container pointer">
    <div class="text-style">
      {{ $t('navigation.back_to_sale') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackToSaleButton',
  props: {
    msg: String,
  },
  methods: {
    backToSale() {
      this.$store.commit(
        'setCurrentDisplayedLotId',
        this.$store.state.context.lotId,
      );
    },
  },
};
</script>

<style scoped>
.back-to-sale-container {
  color: #fff;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 9999;
}
.text-style {
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-top: 16px;
  text-transform: uppercase;
  font-weight: 700;
  opacity: 1;
  z-index: 99999;
}
</style>
