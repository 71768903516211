export const en = {
  header: {
    about: 'About us',
    blog: 'Blog',
    contact: 'Contact',
    account: 'My account',
    help: 'Help',
  },
  footer: {
    legal_info: 'Legal information',
    privacy: 'Privacy & cookies',
    time: "At Baghera/wines, it's",
  },
  lobby: {
    wineOClock: "Wine O'clock",
    type: 'Online',
    sale: 'Sale',
    lots: 'lots',
    vac_start: 'A clock auction will start in ',
    vac_timer: '0 hours 0 minutes {timing} seconds',
  },
  price_area: {
    timer_sec: 'sec',
    timer_text_level: '... Before next bids step',
    timer_text_prep: '... Of preparation',
    timer_text_lot: '... Of presentation',
    price_subtitle: '... Currently',
    currency: 'CHF',
  },
  sale_area: {
    title: 'Catalog',
    lot: 'LOT',
    buy_button: 'Buy',
    bids_start: 'Please note that absentee bid(s)',
    bids_end: 'has been left on this lot',
    bid_error: 'Votre enchère est invalide',
    unsold: 'unsold',
    bought: 'Buy recorded',
    sold: 'THE LOT SOLD FOR',
    lotWon: 'THE LOT IS YOURS AT',
    chooseYourBid: 'CHOOSE YOUR BID',
    setYourBid: 'Set your bid',
    highestBidder: "You're currently the highest bidder",
    slideToBuy: 'Slide to buy',
    estimate: 'Estimate',
    currentBid: 'Current bid',
    currencyConverter: 'Currency converter',
    defaultCurrency: '(default)',
    chooseOne: 'Choose one',
    unsoldLot: 'The lot is unsold',
  },
  details_area: {
    title: 'Lot detail...',
    catalog_title: 'The catalogue',
    catalog_text:
      'If you want to view the catalogue as the auction clock is running, a new tab will open in order not to leave the sale',
    catalog_link: 'View catalogue',
    wine_title: 'Wine description',
    wine_country: 'Country of origin',
    wine_area: 'Production Area',
    wine_subdivision: 'Subdivision',
    wine_produceur: 'Producer',
    wine_classification: 'Classification',
    wine_vintage: 'Vintage',
    wine_titling: 'Alcool Content',
    lot_title: 'Lot informations',
    lot_type: 'Type',
    lot_format: 'Format',
    lot_quantity: 'Quantity',
    lot_nature: 'Nature',
    lot_capacity: 'Capacity',
    lot_condition: 'Condition',
    lot_comment: 'Comment',
    lot_colisage: 'Packing',
  },
  lot_area: {
    lots: 'All lots',
    bids: 'My successfull bids',
    bids_next: "(EXCLUDING BUYER'S COMMISSION OF 22%)",
  },
  sale_end: {
    first: "Wine O'Clock is over for the catalog",
    final: 'Thank you for sharing this moment with us.',
    greetings: 'See you soon !',
    atLeastOneLotWon:
      'You have won at least one lot.\n' +
      'Please go to your account to ensure your\n' +
      'billing and shipping informations.',
  },
  toast: {
    absentee: "AUCTIONNER'S ABSENTEE",
    newBidder: 'NEW BIDDER',
    lot: 'CURRENTLY ON LOT',
    bidWon: 'THE BID IS WITH YOU',
    bidRejectedYour: 'YOUR',
    bidRejected: 'BID HAS NOT BEEN SUCCESSFULL',
    bidPrioritized: 'WRITTEN BID HAS BEEN PRIORITIZED AT',
  },
  navigation: {
    back_to_sale: 'Back to sale',
  },
  fairWarning: {
    desc: 'FAIR WARNING',
  },
  timer_progress_bar: {
    timer_sec: ' seconds left',
    timer_text_pres: 'presentation',
    timer_text_prep: 'before the first lot',
    timer_text_next: 'TIME\'S UP',
    timer_text_bid: 'to buy this lot',
  },
};
