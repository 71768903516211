<template>
  <img
    v-if="isExist()"
    class="img normal-flag"
    :class="{ 'squared-flag': label === 'CHF' }"
    :src="require(`@/assets/img/${label}.png`)"
  />
</template>

<script>
import _ from 'lodash';

export default {
  name: 'CustomFlagImg',
  props: {
    label: String,
  },
  data() {
    return {
      flags: ['CHF', 'CNY', 'EUR', 'HKD', 'SGD', 'USD'],
    };
  },
  methods: {
    isExist() {
      return _.includes(this.flags, this.label);
    },
  },
};
</script>
<style scoped>
.img {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  margin: 0 8px !important;
}
.normal-flag {
  height: 20px !important;
  width: 30px !important;
}
.squared-flag {
  width: 20px !important;
}
</style>
