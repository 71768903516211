<template>
  <Lot-sale-state
    :lotNavigation="true"
    :isLotSold="isLotSold"
    :isLotUnsold="isLotUnsold"
    :isLotWon="!!soldSomeInformation?.soldToMe"
    :lastAcceptedBidAmount="soldSomeInformation?.price"
  />
</template>

<script>
import _ from 'lodash';
import LotSaleState from '@/components/LotSaleState/LotSaleState.vue';
import { getSellingLotInformation } from '@/utils/getSellingLotInformation';
export default {
  name: 'LotNavigationSaleStatus',
  components: {
    LotSaleState,
  },
  computed: {
    soldSomeInformation() {
      return getSellingLotInformation(
        this.$store.state.lotsWon,
        this.$store.state.paddle,
        this.$store.state.currentLot,
      );
    },
    isLotUnsold() {
       return this.$store.getters.isCurrentLotUnsold;
    },
    isLotSold() {
      return !_.isNull(this.soldSomeInformation);
    },
  },
};
</script>
<style scoped></style>
