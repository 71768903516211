const format = 'en-US';

export const formatPrice = (number) => {
  return number
    ?.toLocaleString(format, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(',', "'");
};
