<template>
  <div class="sales-area">
    <div v-if="!catalogLoaded">
      <div class="col-md-6 col-lg-4 end-sale">
        <div class="spinner-border spinner" role="status">
          <span class="visually-hidden spinner">Loading...</span>
        </div>
      </div>
    </div>
    <div v-if="catalogLoaded && !catalogNames[`nom_${$i18n.locale}`]">
      <div class="col-md-6 col-lg-4 end-sale">
        <p>
          {{ $t('sale_end.first') }} {{ catalogNames[`nom_${$i18n.locale}`] }}
          {{ $t('sale_end.final') }}<br />
          {{ $t('sale_end.greetings') }}
        </p>
      </div>
    </div>
    <div
      v-if="catalogLoaded && catalogNames[`nom_${$i18n.locale}`]"
      :class="endStepStyle"
    >
      <CatalogTitle
        :nbrLots="lots.length"
        :catalogName="catalogNames[`nom_${$i18n.locale}`]"
      />

      <end-sale-area v-if="step === 5" />

      <LotDescription v-if="step !== 0 && step !== 1 && step !== 5" />
      <CurrencyConverter
        v-if="step !== 0 && step !== 2 && step !== 5"
          :currencies="currencies"
      />

      <div class="trading-zone">
        <div v-if="step > 1" class="lot-area-wrapper">
          <LotArea
            :lots="lots"
            :current-lot-id="displayedLot?.id"
            :lotsWon="lotsWon"
            :currentLotInfo="displayedLot"
          ></LotArea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CatalogTitle from '@/components/CatalogTitle/CatalogTitle.vue';
import LotArea from '@/components/LotArea/LotArea.vue';
import LotDescription from '@/components/LotDescription/LotDescription.vue';
import EndSaleArea from '@/components/EndSaleArea/EndSaleArea.vue';
import CurrencyConverter from '@/components/CurrencyConverter/CurrencyConverter';
export default {
  sockets: {
    connect: function () {
      console.log('socket connected');
    },
  },
  name: 'Sales',
  components: {
    CatalogTitle,
    LotArea,
    LotDescription,
    EndSaleArea,
    CurrencyConverter,
  },
  computed: {
    catalogLoaded() {
      return this.$store.state.catalogLoaded;
    },
    catalogNames() {
      return this.$store.state.catalogNames;
    },
    endOfSaleTexts() {
      return this.$store.state.endOfSaleTexts;
    },
    lots() {
      return this.$store.state.lots;
    },
    lotsWon() {
      return this.$store.getters.myLotsWon;
    },
    currencies() {
      return this.$store.state.currencies;
    },
    msgStatusLot() {
      return this.$store.state.msgStatusLot;
    },
    step() {
      return this.$store.state.step;
    },
    displayedLot() {
      return this.$store.state.currentLot;
    },
    isCurrentDisplayedLotSelling() {
      return this.$store.getters.isCurrentDisplayedLotSelling;
    },
    currentDisplayedLotId() {
      return this.$store.state.currentDisplayedLotId;
    },
    endStepStyle() {
      return {
        endStepStyle: this.$store.state.step === 5,
      };
    },
    handleScreenWidth() {
      return window.innerHeight > window.innerWidth;
    },
  },

  created() {
    if (window.location.href.includes('/admin')) {
      this.$store.commit('changeIsAdmin', true);
      this.$store.commit('changeIsVisitor', false);
    }
  },

  methods: {
    backToSale() {
      this.$store.commit(
        'setCurrentDisplayedLotId',
        this.$store.state.context.lotId,
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.lot-area-wrapper {
  width: 100%;
  margin-top: 30px;
}
.orientationStyle {
  background-color: red !important;
}
</style>
