<template>
  <div
    class="lot flex center justify-content-between col-md-6 col-lg-4 mx-auto"
  >
    <div
      @click="goToPreviousLot()"
      class="arrow noSelect"
      :class="{ invisible: isPreviousArrowHidden }"
    >
      <img
        :src="require(`@/assets/icons/chevron-left-thin.svg`)"
        class="icon"
      />
    </div>
    <div v-if="displayedLot">
      <h1 class="title">
        <span>{{ $t('sale_area.lot') }}#{{ displayedLot?.position }}</span>
      </h1>
      <h1 class="subTitle">
        <span>{{ displayedLot?.appellation }}</span>
      </h1>
    </div>
    <div
      @click="goToNextLot()"
      class="arrow noSelect"
      :class="{ invisible: isNextArrowHidden }"
    >
      <img
        :src="require(`@/assets/icons/chevron-right-thin.svg`)"
        class="icon"
        @click.stop
      />
    </div>
  </div>

  <div class="orientation" :class="saleStepOrientation">
    <div class="img-area mb10 noSelect" :class="presentationSize">
      <LotPicture />
    </div>

    <div class="fullWidth">
      <div v-if="step >= 2">
        <div class="estimation">
          {{ $t('sale_area.estimate') }} : {{ estimate }} {{ catalogCurrency }}
        </div>
        <div v-if="showSelectedCurrency" class="lightText">
          {{ $t('sale_area.estimate') }} :
          {{ estimateWithCurrency }}
          {{ selectedCurrency }}
        </div>
        <div
          v-if="
            isCurrentDisplayedLotSelling && step >= 3 && lastAcceptedBidAmount
          "
          class="estimation"
        >
          {{ $t('sale_area.currentBid') }} :
          {{ lastAcceptedBidAmountFormatted }}
          {{ catalogCurrency }}
        </div>
        <div
          v-if="
            isCurrentDisplayedLotSelling &&
            step >= 3 &&
            lastAcceptedBidAmount &&
            showSelectedCurrency
          "
          class="lightText"
        >
          {{ $t('sale_area.currentBid') }} :
          {{ lastAcceptedBidAmountWithCurrency }}
          {{ selectedCurrency }}
        </div>
        <!--      <p class="alert-bids" v-if="absenteeBids > 0">
          <span>{{ $t(msgLotHasBisStart) }} </span> <br />
          <span>{{ $t(msgLotHasBisEnd) }} </span>
        </p>-->
      </div>

      <Lot-bid-area
        v-show="step === 3 && isCurrentDisplayedLotSelling"
        :isLotWon="isLotWon"
      />


      <LotNavigationSaleStatus />


    </div>
  </div>
</template>

<script>
import LotPicture from '@/components/LotPicture/LotPicture.vue';
import LotBidArea from '@/components/LotBidArea/LotBidArea.vue';
import LotNavigationSaleStatus from '@/components/LotNavigationSaleStatus/LotNavigationSaleStatus';
import { formatPrice } from '@/utils/formatPrice';
export default {
  name: 'LotDescription',
  components: {
    LotPicture,
    LotBidArea,
    LotNavigationSaleStatus
  },
  methods: {
    goToPreviousLot() {
      this.$store.dispatch('goToPreviousLot');
    },
    goToNextLot() {
      this.$store.dispatch('goToNextLot');
    },
  },
  computed: {
    step() {
      return this.$store.state.step;
    },
    displayedLot() {
      return this.$store.state.currentLot;
    },
    atLeastOneLotWon() {
      return this.$store.state.lotsWon.length >= 1;
    },
    currentCurrency() {
      return this.$store.state.currentCurrency;
    },
    isPreviousArrowHidden() {
      return this.$store.getters.isPreviousArrowHidden;
    },
    isNextArrowHidden() {
      return this.$store.getters.isNextArrowHidden;
    },
    isCurrentDisplayedLotSelling() {
      return this.$store.getters.isCurrentDisplayedLotSelling;
    },
    estimate() {
      return `${formatPrice(this.lowEstimate)} ${
        this.lowEstimate ? '-' : ''
      } ${formatPrice(this.highEstimate)}`;
    },
    lowEstimate() {
      return this.displayedLot.estimation_basse;
    },
    highEstimate() {
      return this.displayedLot.estimation_haute;
    },
    catalogCurrency() {
      return this.$store.state.catalogCurrency;
    },
    showSelectedCurrency() {
      return this.catalogCurrency !== this.selectedCurrency;
    },
    absenteeBids() {
      return this.$store.state.absenteeBids;
    },
    isLotWon() {
      return (
        this.$store.state.context.lastAcceptedBidPaddleNumber ===
        this.$store.state.paddle
      );
    },
    selectedCurrency() {
      return this.$store.state.selectedCurrency;
    },
    lastAcceptedBidAmount() {
      return this.$store.state.context.lastAcceptedBidAmount;
    },
    lastAcceptedBidAmountFormatted() {
      return formatPrice(this.$store.state.context.lastAcceptedBidAmount);
    },
    lastAcceptedBidAmountWithCurrency() {
      return formatPrice(
        this.$store.state.context.lastAcceptedBidAmount *
          this.currentCurrency.rate,
      );
    },
    estimateWithCurrency() {
      const low = this.lowEstimate * this.currentCurrency.rate;
      const high = this.highEstimate * this.currentCurrency.rate;
      return `${formatPrice(low)} ${this.lowEstimate ? '-' : ''} ${formatPrice(
        high,
      )}`;
    },
    msgLotHasBisStart() {
      return this.$store.state.msgLotHasBisStart;
    },
    msgLotHasBisEnd() {
      return this.$store.state.msgLotHasBisEnd;
    },
    presentationStep() {
      return {
        presentationStep:
          this.$store.state.step === 2 && this.isCurrentDisplayedLotSelling,
      };
    },
    presentationSize() {
      return {
        presentationSize:
          this.$store.state.step === 2 && this.isCurrentDisplayedLotSelling,
      };
    },
    saleStepOrientation() {
      return {
        saleStepOrientation:
          this.isCurrentDisplayedLotSelling && this.$store.state.step === 3,
      };
    },
  },
};
</script>

<style scoped>
.lot {
  align-items: center;
}
.title {
  margin-bottom: 0;
  pointer-events: none;
  font-family: 'Lato Light';
  font-size: 20px;
  line-height: 20px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
}
.subTitle {
  pointer-events: none;
  font-family: 'Lato Light';
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
}
.arrow {
  cursor: pointer;
}
.estimation {
  font-family: 'Lato Light';
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
}
.lightText {
  margin: 0 !important;
  color: rgba(179, 179, 179, 1);
  font-family: 'Lato Light';
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
}
.fullWidth {
  width: 100%;
}
.icon {
  pointer-events: none;
  width: 10px;
}
</style>
