<template>
  <div
    v-if="!msgStatusLot && !isVisitor && isAllowedBuying"
    class="lotBidContainer"
  >
    <p v-if="isLotWon" class="lightText">
      {{ $t('sale_area.highestBidder') }}
    </p>
    <p v-else class="lightText">{{ $t('sale_area.slideToBuy') }}</p>
    <slider-button :isLotWon="isLotWon" />
    <p
      v-if="selectedCurrency && catalogCurrency !== selectedCurrency"
      class="lightText"
    >
      {{ price }} {{ selectedCurrency }}
    </p>

    <CustomModalWithSelectedValue
      title="sale_area.chooseYourBid"
      label="sale_area.setYourBid"
      type="bid"
      :list="increments"
    />
  </div>
</template>

<script>
import SliderButton from '@/components/SliderButton/SliderButton.vue';
import CustomModalWithSelectedValue from '@/components/CustomModalWithSelectedValue/CustomModalWithSelectedValue.vue';
import { formatPrice } from '@/utils/formatPrice';

export default {
  name: 'LotBidArea',
  props: {
    isLotWon: Boolean,
  },
  components: {
    SliderButton,
    CustomModalWithSelectedValue,
  },

  computed: {
    price() {
      const price = this.selectedBid
        ? this.selectedBid * this.currentCurrency.rate
        : this.askingPrice * this.currentCurrency.rate;
      return formatPrice(price);
    },
    increments() {
      const increments = this.$store.state.context.increments;
      return increments && increments.length ? increments : [];
    },
    currentCurrency() {
      return this.$store.state.currentCurrency;
    },
    askingPrice() {
      return this.$store.state.context.askingPrice;
    },
    selectedBid() {
      return this.$store.state.selectedBid;
    },
    isVisitor() {
      return this.$store.state.isVisitor;
    },
    isAllowedBuying() {
      return this.$store.state.isAllowedBuying;
    },
    msgStatusLot() {
      return this.$store.state.msgStatusLot;
    },
    catalogCurrency() {
      return this.$store.state.catalogCurrency;
    },
    selectedCurrency() {
      return this.$store.state.selectedCurrency;
    },
  },
};
</script>

<style scoped>
.lotBidContainer {
  margin-top: 20px;
}
.lightText {
  margin: 0 !important;
  color: rgba(179, 179, 179, 1);
  font-family: 'Lato Light';
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
}
</style>
