import 'whatwg-fetch';
import { requestFactory } from './requestFactory';

export class Request {
  method;
  currentBody;
  url;

  constructor(url, method = requestFactory.get()) {
    this.url = url;
    this.method = method;
  }

  setBody(requestBody) {
    this.currentBody = requestBody;
    return this;
  }

  call() {
    if (typeof this.currentBody === 'undefined') {
      return this.method(this.url)
        .then(this.checkStatus)
        .then((res) => res.data);
    } else {
      return this.method(this.url, this.currentBody)
        .then(this.checkStatus)
        .then((res) => res.data);
    }
  }

  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}
