<template>
  <div class="col-xs-12 col-mb-12 col-lg-12 info-relative-lot-detail">
    <div class="col-xs-12 col-md-6 col-lg-6 detail-sub-area">
      <div class="information_line ng-scope">
        <div class="information_label">
          {{ $t('details_area.wine_country') }}
        </div>
        <div class="information_value ng-binding">
          {{ getTranslatedValue('pays') }}
        </div>
      </div>

      <div class="information_line ng-scope">
        <div class="information_label">
          {{ $t('details_area.wine_area') }}
        </div>
        <div class="information_value ng-binding">
          {{ getTranslatedValue('region') }}
        </div>
      </div>

      <div class="information_line ng-scope">
        <div class="information_label">
          {{ $t('details_area.wine_subdivision') }}
        </div>
        <div class="information_value ng-binding">
          {{ getLotInfoValue('subdivision') }}
        </div>
      </div>
      <div class="information_line ng-scope">
        <div class="information_label">
          {{ $t('details_area.wine_produceur') }}
        </div>
        <div class="information_value ng-binding">
          {{ getLotInfoValue('producteur') }}
        </div>
      </div>
      <div class="information_line ng-scope">
        <div class="information_label">
          {{ $t('details_area.wine_classification') }}
        </div>
        <div class="information_value ng-binding">
          {{ getLotInfoValue('classement') }}
        </div>
      </div>
      <div class="information_line ng-scope">
        <div class="information_label">
          {{ $t('details_area.wine_vintage') }}
        </div>
        <div class="information_value ng-binding">
          {{ getLotInfoValue('millesime') }}
        </div>
      </div>
      <div class="information_line ng-scope">
        <div class="information_label">
          {{ $t('details_area.wine_titling') }}
        </div>
        <div class="information_value ng-binding">
          {{ getLotInfoValue('titrage') }}
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-6">
      <div class="information_line ng-scope">
        <div class="information_label">
          {{ $t('details_area.lot_type') }}
        </div>
        <div class="information_value ng-binding">
          {{ getTranslatedValue('type') }}
        </div>
      </div>

      <div class="information_line ng-scope">
        <div class="information_label">
          {{ $t('details_area.lot_format') }}
        </div>
        <div class="information_value ng-binding">
          {{ getTranslatedValue('format') }}
        </div>
      </div>

      <div class="information_line ng-scope">
        <div class="information_label">
          {{ $t('details_area.lot_quantity') }}
        </div>
        <div class="information_value ng-binding">
          {{ getLotInfoValue('quantite') }}
        </div>
      </div>

      <div class="information_line ng-scope">
        <div class="information_label">
          {{ $t('details_area.lot_nature') }}
        </div>
        <div class="information_value ng-binding">
          {{ getTranslatedValue('nature') }}
        </div>
      </div>

      <div class="information_line ng-scope">
        <div class="information_label">
          {{ $t('details_area.lot_capacity') }}
        </div>
        <div class="information_value ng-binding">
          {{ getLotInfoValue('contenance') }}
        </div>
      </div>
      <div class="information_line ng-scope">
        <div class="information_label">
          {{ $t('details_area.lot_colisage') }}
        </div>
        <div class="information_value ng-binding">
          {{ getTranslatedValue('colisage') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'LotInformation',
  props: {
    currentLotInfo: Object,
  },
  methods: {
    getTranslatedValue(prefix) {
      return _.get(this.currentLotInfo, prefix + '_' + this.$i18n.locale, '');
    },
    getLotInfoValue(prefix) {
      return _.get(this.currentLotInfo, prefix, '');
    },
  },
};
</script>
<style scoped>
.information_label {
  color: rgba(0, 0, 0, 1);
  font-family: 'Lato Bold';
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0;
  line-height: 22px;
}

.information_value {
  color: rgba(0, 0, 0, 1);
  font-family: 'Lato Light';
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0;
  line-height: 22px;
}
</style>
