<template>
  <div class="col-md-6 col-lg-4 end-sale msg">
    {{ (endOfSaleTexts || {})[`end_text_${$i18n.locale}`] }}
  </div>
  <div class="col-md-6 col-lg-4 end-sale lotWon" v-if="atLeastOneLotWon">
    {{ $t('sale_end.atLeastOneLotWon') }}
  </div>
</template>
<script>
export default {
  name: 'EndSaleArea',
  computed: {
    step() {
      return this.$store.state.step;
    },
    atLeastOneLotWon() {
      return this.$store.getters.myLotsWon.length >= 1;
    },
    endOfSaleTexts() {
      return this.$store.state.endOfSaleTexts;
    },
  },
};
</script>
<style scoped>
.msg {
  margin-top: 30px;
}
.lotWon {
  margin-top: 100px;
}
</style>
