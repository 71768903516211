<template>
  <div
    class="fairWarning slide-up-now-opacity"
    v-if="
      timer <= stepTotalTimes.warnTime &&
      step === 3 &&
      isCurrentDisplayedLotSelling
    "
  >
    <p class="fairWarningText pulsate">{{ $t('fairWarning.desc') }}</p>
  </div>
</template>

<script>
export default {
  name: 'FairWarning',
  props: {
    timer: {
      type: Number,
    },
  },
  computed: {
    stepTotalTimes() {
      return this.$store.state.stepTotalTimes;
    },
    step() {
      return this.$store.state.step;
    },
    isCurrentDisplayedLotSelling() {
      return this.$store.getters.isCurrentDisplayedLotSelling;
    },
  },
};
</script>

<style scoped>
.fairWarning {
  z-index: 1000;
  position: fixed;
  bottom: 60px;
  width: 100%;
  text-align: center;
  height: 60px;
  background-color: yellow;
  transition: all 0.3s ease-in;
}
.fairWarningText {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(0, 0, 0, 1);
  font-family: 'Lato Bold';
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.slide-up-now-opacity {
  opacity: 0;
  animation: slide-up-opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1) 500ms forwards;
}

.pulsate {
  animation: pulsate 3s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.5;
}
@keyframes pulsate {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes slide-up-opacity {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
