<template>
  <div>
    <div class="progress">
      <div
        class="progress-bar bg-warning"
        role="progressbar"
        :style="
          'width: ' +
          percent +
          '%; transition-duration: 10ms;transition:width 100ms linear;'
        "
      ></div>
    </div>
  </div>
</template>
<script>
const UNIT_MULTIPLIER = 1000;

export default {
  name: 'ProgressBar',

  data() {
    return {
      percent: 0,
      totalTime: 1000,
      currentTime: 0,
      serverSynchronized: false,
      currentAnimationFrame: null,
      currentStep: -1,
      baseTime: 0,
    };
  },
  computed: {
    step() {
      return this.$store.state.step;
    },
    stepTotalTimes() {
      return this.$store.state.stepTotalTimes;
    },
    timingFromBack() {
      return this.$store.state.genericTiming;
    },
  },
  methods: {
    startTimer() {
      if (this.currentAnimationFrame) {
        cancelAnimationFrame(this.currentAnimationFrame);
        this.currentAnimationFrame = null;
      }

      this.baseTime = Date.now();
      let barAnimation = () => {
        let current = this.currentTime;
        const total = this.totalTime;
        if (Math.abs(this.timingFromBack * 1000 - current) > 1000) {
          current = this.timingFromBack * 1000;
        }

        const newTime = Date.now();
        current = Math.max(0, current - (newTime - this.baseTime));
        this.baseTime = newTime;
        this.percent = Math.min(
          Math.max((0, (current / total) * 100).toFixed(3)),
          100,
        );
        this.currentTime = current;
        if (current <= 0 || this.percent <= 0) {
          this.percent = 0;
        } else {
          window.requestAnimationFrame(barAnimation);
        }
      };
      this.currentAnimationFrame = window.requestAnimationFrame(barAnimation);
    },
  },
  watch: {
    step() {
      if (this.step !== this.currentStep) {
        this.currentStep = this.step;
        if (this.step === 1) {
          this.totalTime = this.stepTotalTimes.prepTime * UNIT_MULTIPLIER;
          this.currentTime = this.totalTime;
          this.startTimer();
        } else if (this.step === 2) {
          this.totalTime = this.stepTotalTimes.presTime * UNIT_MULTIPLIER;
          this.currentTime = this.totalTime;
          this.startTimer();
        } else if (this.step === 3) {
          this.totalTime = this.stepTotalTimes.bidTime * UNIT_MULTIPLIER;
          this.currentTime = this.totalTime;
          this.startTimer();
        }
      }
    },
  },
};
</script>
<style scoped>
.timerContainer {
  height: 60px;
}
</style>
