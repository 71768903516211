import { bagheraAuctionUrl } from '@/utils/serverConf';

export const redirectToUrl = (url) => {
  window.location.href = url;
};

export const redirectToLogout = () => {
  const lang = localStorage.getItem('language');
  redirectToUrl(`${bagheraAuctionUrl}/${lang}/auth/logout`);
};
