<template>
  <div
    class="col-xs-12 col-md-12 col-lg-4 lot-responsive accordion-header noSelect"
  >
    <div
      @click="handleToggle()"
      class="toggle"
      :class="[isOpen && !lgScreen ? 'open shadow-sm' : '']"
    >
      <div class="content">
        <p class="title">{{ title }}</p>
        <p v-show="subtitle" class="title subtitle">{{ subtitle }}</p>
      </div>
      <fa v-show="showArrow && isOpen" icon="fa-chevron-up" />
      <fa v-show="showArrow && !isOpen" icon="fa-chevron-down" />
    </div>
    <div
      class="all-lots accordion-collapse collapse"
      :class="[
        isOpen || lgScreen ? 'border-bottom show' : '',
        scrollable ? 'overflowed' : '',
      ]"
    >
      <table class="lots_display" id="table">
        <slot></slot>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    title: { type: String },
    subtitle: { type: String },
    showArrow: { type: Boolean },
    lgScreen: { type: Boolean },
    scrollable: { type: Boolean },
  },
  data() {
    return {
      isOpen: false,
      timer: null,
      waitingForUpdate: false,
    };
  },
  updated() {
    if (this.waitingForUpdate) {
      this.waitingForUpdate = false;
      this.$emit('created');
    }
  },
  methods: {
    handleToggle() {
      if (this.showArrow && !this.lgScreen) {
        this.waitingForUpdate = true;
        this.isOpen = !this.isOpen;
      }
    },
  },
};
</script>

<style scoped>
.all-lots {
  overflow-y: auto;
}
.overflowed {
  overflow-y: hidden !important;
}
.accordion-header {
  cursor: pointer;
}
.toggle {
  height: 46px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid #cccccc;
  justify-content: space-between;
}
.open {
  background-color: yellow;
  border-bottom: 1px solid #cccccc;
}
.border-bottom {
  border-bottom: 1px solid #cccccc;
}
.title {
  margin: 5px 0;
  color: rgba(0, 0, 0, 1);
  font-family: 'Lato Bold';
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
}
.content {
  text-align: start;
}
.subtitle {
  margin-top: 5px;
}
</style>
