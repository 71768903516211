<template>
  <div v-if="isLotSold && isLotWon"
       class="lot-won area">
    <div>
      {{ $t('sale_area.lotWon') }} {{ catalogCurrency }} {{ lastAcceptedBidAmountFormatted }}
    </div>
    <div class="lightText"
         v-if="showSelectedCurrency">
      {{ lastAcceptedBidRatio }} {{ selectedCurrency }}
    </div>
  </div>
  <div v-else-if="isLotSold && !isLotWon"
       class="sold area">
    <div>
      {{ $t('sale_area.sold') }} {{ catalogCurrency }} {{ lastAcceptedBidAmountFormatted }}
    </div>
    <div class="lightText"
         v-if="showSelectedCurrency">
      {{ lastAcceptedBidRatio }} {{ selectedCurrency }}
    </div>

  </div>
  <div v-if="isLotUnsold"
       class="sold area">
    {{ $t('sale_area.unsoldLot') }}
  </div>
</template>

<script>
import { formatPrice } from '@/utils/formatPrice';
export default {
  name: 'LotSaleState',
  props: {
    isLotWon: Boolean,
    isLotSold: Boolean,
    isLotUnsold: Boolean,
    lastAcceptedBidAmount: {
      type: [Number, null],
      default: null,
    },
  },
  computed: {
    catalogCurrency() {
      return this.$store.state.catalogCurrency;
    },
    selectedCurrency() {
      return this.$store.state.selectedCurrency;
    },
    currentCurrency() {
      return this.$store.state.currentCurrency;
    },
    showSelectedCurrency() {
      return this.catalogCurrency !== this.selectedCurrency;
    },
    lastAcceptedBidRatio() {
      return formatPrice(
        this.lastAcceptedBidAmount * this.currentCurrency.rate,
      );
    },
    lastAcceptedBidAmountFormatted() {
      return formatPrice(this.lastAcceptedBidAmount);
    },
  },
};
</script>

<style scoped>
.area {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 86px;
  padding: 15px;
  margin-top: 30px;
}

.lot-won {
  background-color: #ffff00;
  color: rgba(0, 0, 0, 1);
  font-family: 'Lato Regular';
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
}

.sold {
  background-color: #808080;
  color: rgba(255, 255, 255, 1);
  font-family: 'Lato Regular';
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
}

.lightText {
  opacity: 0.8;
  font-family: 'Lato Light';
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
}</style>
