<template>
  <Header />
  <div class="main" :class="firstStepStyle">
    <router-view />
  </div>
  <Footer />
</template>
<script>
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';
import router from './router';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  created: function () {
    window.addEventListener('keyup', this.keyHandler);
    this.$store.dispatch('getCurrencies');
    router.replace({ path: '/' });
  },
  computed: {
    firstStepStyle() {
      return {
        firstStepStyle: this.$store.state.step === 1,
      };
    },
  },
  methods: {
    keyHandler(event) {
      if (event.key === 'ArrowLeft') {
        this.$store.dispatch('goToPreviousLot');
      } else if (event.key === 'ArrowRight') {
        this.$store.dispatch('goToNextLot');
      }
    },
  },
};
</script>

<style lang="scss">
@import url('assets/scss/global.scss');
@import url('assets/scss/_fonts.scss');
</style>
